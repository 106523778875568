body {
  margin: 0;
  font-family: "Raleway" ,sans-serif;
  -webkit-font-smoothing: antialiased;
}

.mobile-logo {
  height: 40px;
}

.mobile-nav-link {
  text-decoration: none;
  color: inherit;
}

.app-content {
  padding: 0 20px;
}

.form-container {
  display: flex;
  justify-content: space-between
}

.form-container > div {
  width: 48%;
}

.footer {
  display: none;
}

.banner {
  width: 100vw;
}

@media (min-width: 600px) {
  .app-content {
    max-width: 850px;
    margin: 0 auto;
    min-height: calc(100vh - 140px); /* nav bar */
  }

  .contact-container {
    margin: 0 auto;
  }

  .footer {
    display: flex;
    justify-content: center;
    background-color: rgb(0, 144, 67);
    padding: 20px 0;
    margin-top: 50px;
  }

  .footer > p {
    margin: 0 5px;
    color: white;
  }
}
